



import Vue from 'vue'

interface ParamsType {
  email: string | null | (string | null)[]
  referral_code?: string | null | (string | null)[]
}

export default Vue.extend({
  name: 'Login',
  layout: 'clean',

  mounted() {
    let previousPath: String = decodeURIComponent(
      this.$route.query.to?.toString() || ''
    )
    if (previousPath == '/login') {
      previousPath = ''
    }
    this.$loadingScreen.show = false
    if (previousPath) {
      this.$auth.$storage.setUniversal('redirect', previousPath)
    }
    const params: ParamsType = { email: this.$route.query.email }
    const urlReferralCode = this.$route.query.referral_code
    const localStorageReferralCode = localStorage.getItem('referral_code')
    if (urlReferralCode) params.referral_code = urlReferralCode
    else if (localStorageReferralCode)
      params.referral_code = localStorageReferralCode
    localStorage.removeItem('referral_code')

    this.$auth.loginWith('auth0', { params })
  }
})
